import { ReactNode, useState } from 'react';
import { Box, IconButton, SxProps, Typography } from '@mui/material';
import { CustomLabel } from 'app/components/CustomLabel';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as ArrowIcon } from 'images/icons/ic-chevron-up.svg';

interface OwnProps {
  isRolled?: boolean;
  children: ReactNode;
  sx?: SxProps;
  rolledWrapperSx?: SxProps;

  name?: string;
  label?: string;
  description?: string;
  selectedLabels?: string;
}

export const RolledWrapper = ({
  isRolled,
  sx,
  children,
  name,
  label,
  description,
  selectedLabels,
  rolledWrapperSx,
}: OwnProps) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(true);

  const handleToggle = () => {
    if (isRolled) {
      setIsExpanded(!isExpanded);
    }
  };

  const divider = selectedLabels && selectedLabels.length && '|';

  return (
    <Box
      sx={{
        ...(isRolled && {
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderColor: 'borderColor',
          '&:last-child': {
            borderBottomWidth: '0px',
          },
        }),
        ...sx,
      }}
    >
      <Box
        onClick={handleToggle}
        sx={{
          display: 'flex',
          width: '100%',
          gap: '16px',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <CustomLabel
            label={label}
            name={name}
            description={isExpanded ? description : null}
            sx={{
              label: {
                cursor: isRolled ? 'pointer' : 'arrow',
              },
            }}
          />
          {isRolled && !isExpanded && selectedLabels && (
            <Typography sx={{ marginBottom: '16px' }}>
              {divider} {selectedLabels}
            </Typography>
          )}
        </Box>
        {isRolled && (
          <IconButton sx={{ width: 'fit-content', height: 'fit-content', padding: 0 }}>
            <ArrowIcon
              style={{
                transform: isExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: 'transform 0.3s ease',
                color: theme.palette.navyBlue,
              }}
            />
          </IconButton>
        )}
      </Box>
      {(isExpanded || !isRolled) && (
        <Box sx={{ paddingBottom: '16px', ...rolledWrapperSx }}>{children}</Box>
      )}
    </Box>
  );
};
