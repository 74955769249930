import { Box, ButtonBase } from '@mui/material';
import { TFunction } from 'i18next';
import { ReactComponent as CalendarIcon } from 'images/icons/ic-calendar.svg';
import { ReactComponent as ListIcon } from 'images/icons/ic-list.svg';
import { ReactComponent as AddIcon } from 'images/icons/ic-plus-circle-v2.svg';
import { ReactComponent as ActionsIcon } from 'images/icons/ic-actions.svg';
import { ReactComponent as ReportsIcon } from 'images/icons/ic-reports-v2.svg';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import appActions from 'redux/actions/app/app';
import { TabMenuItemText } from 'app/components/TabMenu/_components/TabMenuItemText';
import paths from 'app/router/paths';

const tabMenuControls = (t: TFunction) => [
  {
    to: paths.calendar.reservationList,
    icon: <ListIcon />,
    name: t('tabMenuList'),
  },
  {
    to: paths.calendar.calendar,
    icon: <CalendarIcon />,
    name: t('tabMenuCalendar'),
  },
  {
    icon: <AddIcon />,
    name: t('tabMenuAdd'),
    showAddButton: true,
  },
  {
    to: paths.calendar.statistics,
    icon: <ReportsIcon />,
    name: t('tabMenuStatistics'),
  },
  {
    to: paths.calendar.quickActions,
    icon: <ActionsIcon />,
    name: t('tabMenuQuick'),
  },
];

const activeStyles = {
  color: 'darkBlue',
  '.tab-menu-link': {
    color: 'darkBlue',
  },
};

const addReservationStyles = {
  backgroundColor: 'blueDodger',
  color: 'white',
  width: '48px',
  height: '48px',
  borderRadius: '50%',
  margin: '0 16px',
};

const flexStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
};

export const TabMenu = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const openModal = () => {
    dispatch(appActions.openModal());
  };

  const tabMenuControlsToRender = tabMenuControls(t).map(({ to, icon, name, showAddButton }) => {
    const isActive = to === pathname;

    if (showAddButton) {
      return (
        <ButtonBase
          key={name}
          onClick={openModal}
          sx={{
            ...flexStyles,
            ...addReservationStyles,
          }}
        >
          {icon}
          <TabMenuItemText name={name} sx={{ color: 'white' }} />
        </ButtonBase>
      );
    } else if (to) {
      return (
        <NavLink to={to} key={name} style={{ textDecoration: 'none' }}>
          <Box
            sx={{
              width: '66px',
              position: 'relative',
              color: 'comet',
              ...flexStyles,
              ...(isActive && activeStyles),
            }}
          >
            {icon}
            <TabMenuItemText name={name} />
          </Box>
        </NavLink>
      );
    }
  });

  return (
    <Box
      sx={{
        backgroundColor: 'brandWhite',
        minHeight: '64px',
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        borderRadius: '24px 24px 0px 0px',
        boxShadow: '0px -4px 23px -6px rgba(0, 10, 102, 0.08)',
        display: { xs: 'flex', md: 'none' },
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
      }}
      className="no-print"
    >
      {tabMenuControlsToRender}
    </Box>
  );
};
