import { styled } from '@mui/material/styles';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { SvgIconProps } from '@mui/material';

interface IRotatingArrowProps extends SvgIconProps {
  open: boolean;
  isMargin?: boolean;
  arrowColor?: string;
}

const RotatingArrowBase = ({
  open,
  isMargin = true,
  arrowColor,
  sx,
  ...props
}: IRotatingArrowProps) => (
  <KeyboardArrowDownOutlinedIcon
    {...props}
    sx={{
      color: arrowColor,
      fontSize: 24,
      marginRight: isMargin ? '12px' : 0,
      transform: open ? 'rotate(180deg)' : 'rotate(0)',
      ...sx,
    }}
  />
);

export const RotatingArrow = styled(RotatingArrowBase)<IRotatingArrowProps>(
  ({ theme, arrowColor }) => ({
    color: arrowColor || theme.palette.navyBlue,
  }),
);
