import { ReactNode } from 'react';
import { Box, SxProps } from '@mui/material';

interface OwnProps {
  children: ReactNode;
  sx?: SxProps;
  className?: string;
}

export const ReservationIconWrapper = ({
  children,
  sx,
  className = 'timeline-icon-wrapper',
}: OwnProps) => {
  return (
    <Box
      className={className}
      component="li"
      sx={{
        minWidth: '16px',
        height: '16px',
        borderRadius: '3px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        svg: {
          width: '18px',
          height: '18px',
        },
        fontSize: 12,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
