import { styled } from '@mui/material/styles';
import { FormHelperText } from '@mui/material';

export const StyledCustomErrorMessage = styled(FormHelperText)(({ theme }) => ({
  wordBreak: 'break-all',
  color: theme.palette.accent.red,
  '&:first-letter': {
    textTransform: 'uppercase',
  },
}));
