import React, { ReactNode, useState } from 'react';
import { AuthContext } from 'hooks/useAuth';
import { getToken, setToken, removeToken } from 'utils/auth/tokenManagement';
import { logoutCall } from 'api/auth';
import { createMomentUtc } from 'utils/date-time/createMomentUtc';
import { useAppDispatch } from 'redux/hooks';
import { AppActionTypes } from 'types/app/app';
import rootActions from 'redux/actions/app/root';

interface OwnProps {
  children: ReactNode;
}
export const TokenProvider = ({ children }: OwnProps) => {
  const [token, setLocalToken] = useState(getToken() || '');
  const today = createMomentUtc().startOf('day').unix();
  const dispatch = useAppDispatch();

  const setTokenToLocalStorage = (data: string) => {
    setToken(data);
    setLocalToken(data);
  };

  const handleLogout = () => {
    logoutCall().finally(() => {
      removeToken();
      setLocalToken('');
      localStorage.clear();
      dispatch(rootActions.resetStoreToDefault());
      dispatch({
        type: AppActionTypes.ChangeCurrentDate,
        payload: {
          current_date: today,
        },
      });
    });
  };

  return (
    <AuthContext.Provider value={{ token, setTokenToLocalStorage, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
