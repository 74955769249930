import { Scrollbars } from 'react-custom-scrollbars';
import { ReactNode } from 'react';
import './ScrollArea.scss';

interface Props {
  className?: string;
  children?: ReactNode;
  height?: string;
  width?: string;
  style?: object;
  scrollRef?: any;
}

const ScrollArea = ({
  className,
  height = '100%',
  width = '100%',
  children,
  style,
  scrollRef,
}: Props) => {
  return (
    <Scrollbars
      renderTrackHorizontal={(props) => <div {...props} style={{ display: 'none' }} />}
      renderThumbHorizontal={(props) => <div {...props} style={{ display: 'none' }} />}
      autoHide
      className={`scroll-area${className ? ` ${className}` : ''} print-scroll`}
      style={{ height, width, ...style }}
      ref={scrollRef}
    >
      {children}
    </Scrollbars>
  );
};

export default ScrollArea;
