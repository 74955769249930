import { Box, SxProps } from '@mui/material';
import { ReactNode } from 'react';

interface OwnProps {
  children: ReactNode;
  sx?: SxProps;
}

export const ReservationBadge = ({ children, sx }: OwnProps) => {
  return (
    <Box
      sx={{
        borderRadius: '10px',
        lineHeight: 1.1,
        fontSize: 10,
        fontWeight: 600,
        color: 'white',
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'fit-content',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
