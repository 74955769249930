import { SxProps, Typography } from '@mui/material';

interface OwnProps {
  name: string;
  sx?: SxProps;
}

export const TabMenuItemText = ({ name, sx }: OwnProps) => {
  return (
    <Typography
      className="tab-menu-link"
      sx={{
        color: 'comet',
        fontSize: 10,
        fontWeight: 700,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '65px',
        ...sx,
      }}
    >
      {name}
    </Typography>
  );
};
