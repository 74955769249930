import { ReactComponent as DepositIcon } from 'images/icons/Badges/ic-deposit.svg';
import { ReactComponent as DiscountIcon } from 'images/icons/Badges/ic-discount.svg';
import { ReactComponent as UsdCircleIcon } from 'images/icons/Badges/ic-payment.svg';
import { ReactComponent as EventIcon } from 'images/icons/Badges/ic-special-event.svg';
import { ReactComponent as SavedCardIcon } from 'images/icons/Badges/ic-saved-card.svg';
import { useReservationBadgeConditions } from 'hooks/useReservationBadgeConditions';
import { ReservationIconWrapper } from 'app/components/Wrappers/ReservationIconWrapper';

interface OwnProps {
  item: any;
}

const PromotionIcons = ({ item }: OwnProps) => {
  const getReservationBadgeConditions = useReservationBadgeConditions();

  const {
    showPaidIcon,
    showDepositIcon,
    showDiscountIcon,
    showEventIcon,
    showSavedCardIcon,
    paymentCondition,
  } = getReservationBadgeConditions(item);

  return (
    <>
      {showDepositIcon && (
        <ReservationIconWrapper>
          <DepositIcon width={16} height={16} />
        </ReservationIconWrapper>
      )}
      {showDiscountIcon && (
        <ReservationIconWrapper>
          <DiscountIcon width={16} height={16} />
        </ReservationIconWrapper>
      )}
      {showEventIcon && (
        <ReservationIconWrapper>
          <EventIcon width={16} height={16} />
        </ReservationIconWrapper>
      )}
      {showSavedCardIcon && (
        <ReservationIconWrapper>
          <SavedCardIcon width={16} height={16} />
        </ReservationIconWrapper>
      )}
      {paymentCondition && (
        <ReservationIconWrapper>
          <UsdCircleIcon width={16} height={16} />
        </ReservationIconWrapper>
      )}
      {showPaidIcon && (
        <ReservationIconWrapper>
          <UsdCircleIcon width={16} height={16} />
        </ReservationIconWrapper>
      )}
    </>
  );
};

export default PromotionIcons;
