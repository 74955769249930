import { SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface OwnProps {
  children: ReactNode;
  sx?: SxProps;
}

export const MainHeading = ({ children, sx }: OwnProps) => {
  return (
    <Typography component="h2" sx={{ fontSize: '20px', fontWeight: 600, ...sx }}>
      {children}
    </Typography>
  );
};
