import React from 'react';
import { Backdrop, CircularProgress, Box, SxProps } from '@mui/material';

type Props = {
  open: boolean;
  isLoader?: boolean;
  sx?: SxProps;
};

export const ProgressOverlay = ({ open = false, isLoader, sx }: Props) => {
  if (isLoader && open) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'brandWhite',
          zIndex: 2,
          opacity: 0.8,
          ...sx,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Backdrop open={open} sx={{ zIndex: 2, backgroundColor: 'brandWhite' }}>
      <CircularProgress />
    </Backdrop>
  );
};
