import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import paths from 'app/router/paths';

interface OwnProps {
  children: ReactNode;
}
export const PrivateRoute = ({ children }: OwnProps) => {
  const { token } = useAuth();
  return token ? <>{children}</> : <Navigate to={paths.auth.login} />;
};
