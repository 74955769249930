import { Backdrop, CircularProgress } from '@mui/material';

export const CustomBackdrop = () => {
  return (
    <Backdrop
      sx={{
        color: 'brandYellow',
        zIndex: 10,
        backgroundColor: 'overlay',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
