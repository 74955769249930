import { ReactNode, useContext } from 'react';
import { Box, Typography, IconButton, SxProps } from '@mui/material';
import { SubmitButton } from 'app/pages/RestaurantSettings/_components/SubmitButton';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from 'app/pages/RestaurantSettings/_components/SettingsWrapper';
import { ReactComponent as ArrowLeftIcon } from 'images/icons/ic-arrow-left.svg';
import { useScreenSize } from 'hooks/useScreenSize';
import { mobileTabMenuHeight } from 'styles/constants/spacing';
import { useTheme } from '@mui/material/styles';

interface OwnProps {
  scrollRef: any;
  title: string;
  additionalElements?: ReactNode;
  withoutSaveButton?: boolean;
  onBack?: () => void;
  sx?: SxProps;
  isBackArrow?: boolean;
  isDirty?: boolean;
}

export const SettingHeader = ({
  scrollRef,
  title,
  additionalElements,
  withoutSaveButton = false,
  onBack,
  sx,
  isBackArrow,
  isDirty = true,
}: OwnProps) => {
  const { t } = useTranslation();
  const { isMax760 } = useScreenSize();
  const { setNavigationValues } = useContext(NavigationContext);
  const theme = useTheme();

  const scrollToTop = () => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollToTop();
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        position: 'sticky',
        top: 0,
        left: '64px',
        zIndex: 3,
        marginBottom: '12px',
        backgroundColor: 'linkWater2',
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          height: '12px',
          backgroundColor: 'linkWater2',
        },
        '&::before': {
          top: '-12px',
        },
        '&::after': {
          bottom: '-12px',
        },
        bottom: '12px',
        ...sx,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'brandWhite',
          borderRadius: theme.borderRadius.small,
          display: 'flex',
          alignItems: 'center',
          padding: '12px 24px',
          justifyContent: 'flex-end',
          gap: '12px',
          minHeight: `${mobileTabMenuHeight}px`,
        }}
      >
        <Box sx={{ flex: 1, minWidth: 0, display: 'flex', alignItems: 'center' }}>
          <IconButton
            sx={{
              display: isBackArrow || isMax760 ? 'block' : 'none',
              borderRadius: '50%',
              padding: 0,
              width: '24px',
              height: '24px',
              color: 'navyBlue',
              marginRight: '12px',
            }}
            onClick={onBack ? onBack : () => setNavigationValues({ visible: false })}
          >
            <ArrowLeftIcon width={24} height={24} />
          </IconButton>
          <Typography
            onClick={scrollToTop}
            sx={{
              fontSize: '20px',
              fontWeight: 600,
              textAlign: 'left',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%',
              cursor: 'pointer',
            }}
          >
            {title}
          </Typography>
        </Box>
        {additionalElements}
        {!withoutSaveButton && (
          <SubmitButton isDisabled={!isDirty} withMargin={false}>
            {t('basicInformationSave')}
          </SubmitButton>
        )}
      </Box>
    </Box>
  );
};
