import { Statuses } from 'types/reservation';
import moment from 'moment-timezone';
import PromotionIcons from '../PromotionIcons';
import { ReactComponent as SpenderIcon } from 'images/icons/ic-money.svg';
import { ReactComponent as WalkinsIcon } from 'images/icons/Badges/ic-walkins.svg';
import { ReactComponent as SpecialOfferIcon } from 'images/icons/Badges/ic-special-offer.svg';
import { ReactComponent as CommentIcon } from 'images/icons/Badges/ic-comment.svg';
import { ReactComponent as DietaryRequirementIcon } from 'images/icons/Badges/ic-dietary-requirement.svg';
import { ReactComponent as LockIcon } from 'images/icons/Badges/ic-lock.svg';
import { ReactComponent as GuestTagIcon } from 'images/icons/Badges/ic-guest-tag.svg';
import { ReactComponent as ImportantMessageIcon } from 'images/icons/Badges/ic-important-message.svg';
import OriginIcon from '../OriginIcon';
import './TimelineItem.scss';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';
import { ReservationBadge } from 'app/components/ReservationBadge';
import { ReservationIconWrapper } from 'app/components/Wrappers/ReservationIconWrapper';
import { ITimelineItem } from 'app/components/CustomTimeline/_types/customTimeline';
import { OriginalTime } from 'app/components/NewReservationList/_components/Reservation/_components/OriginalTime';
import { ReactElement } from 'react';
import { reservationTagIcons } from 'app/components/NewReservationList/_components/Reservation/_config';
import { timestamp } from 'utils/date-time/timestamp';
import { getReservationTitle } from 'utils/reservation/reservations';

interface OwnProps {
  item: ITimelineItem;
  itemContext: any;
  getItemProps: any;
  getResizeProps: any;
}

export const TimelineItem = ({ item, itemContext, getItemProps, getResizeProps }: OwnProps) => {
  const reservation = item?.reservation || {};
  const settings = item?.settings || {};
  const badges = item?.settings?.badges || {};

  const reservationTags = reservation?.tags;

  const tagsToRender = reservationTags?.reduce((tagsArray: ReactElement[], tag) => {
    const tagIcon = reservationTagIcons.find((icon) => icon.name === tag);
    if (!tagIcon) return tagsArray;

    const tagElement = <ReservationIconWrapper>{tagIcon.icon}</ReservationIconWrapper>;

    tagsArray.push(tagElement);
    return tagsArray;
  }, []);

  const {
    specialOfferCondition,
    isPromotionType,
    isBigSpender,
    isVip,
    showInitialTimeBadge,
    originCondition,
    guestCondition,
    showTime,
    showCommentCondition,
    isDietaryRequirementExist,
    isBlacklisted,
    isLocked,
    areGuestTagsExist,
    isImportantMessage,
  } = badges;

  const formattedTimeRange = item?.reservation
    ? `${moment(item.start).format('HH:mm')} - ${moment(
        timestamp(item.end).ceilToInterval(item.interval),
      ).format('HH:mm')}`
    : '';

  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  // const tooltipId = 'timeline-item-tooltip-id';
  const itemProps = { ...getItemProps(item.itemProps) };

  delete itemProps.style.background;
  delete itemProps.style.border;
  delete itemProps.style.color;

  const child =
    itemProps.style.top &&
    itemProps.style.top.map((top: any, index: number) => {
      const singleDivProps = {
        ...itemProps,
      };
      if (index === 0) {
        singleDivProps.className += 'item-first ';
      }
      if (index === itemProps.style.top.length - 1) {
        singleDivProps.className += 'item-last ';
      }
      if (
        index !== itemProps.style.top.length - 1 &&
        itemProps.style.top[index + 1] ===
          itemProps.style.top[index] + parseInt(itemProps.style.height)
      ) {
        singleDivProps.className += 'item-stacked-top ';
      }
      if (
        index > 0 &&
        itemProps.style.top[index] ===
          itemProps.style.top[index - 1] + parseInt(itemProps.style.height)
      ) {
        singleDivProps.className += 'item-stacked-bottom ';
      }

      const key = itemProps.key + '-' + index;
      const style = { ...singleDivProps.style, top: top + 'px' };

      return (
        <div {...singleDivProps} key={key} style={style}>
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}
          <Box
            // data-tooltip-id={tooltipId}
            className="rct-item-content"
            sx={{
              maxHeight: `${itemContext.dimensions.height}`,
            }}
          >
            {index === 0 && (
              <div className="reservation-wrapper">
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                      {getReservationTitle(reservation.status, item.title)}
                    </Typography>
                    {isVip && (
                      <ReservationBadge sx={{ backgroundColor: 'accent.red' }}>
                        VIP
                      </ReservationBadge>
                    )}
                    {isBigSpender && (
                      <ReservationBadge sx={{ color: 'accent.green', padding: 0 }}>
                        <SpenderIcon width={15} height={15} />
                      </ReservationBadge>
                    )}
                    {isBlacklisted && (
                      <ReservationBadge sx={{ backgroundColor: 'darkNavyBlue' }}>
                        BAN
                      </ReservationBadge>
                    )}
                    {areGuestTagsExist && (
                      <ReservationIconWrapper
                        className=""
                        sx={{
                          backgroundColor: 'accent.blue',
                          color: 'white',
                          borderRadius: '50%',
                          width: '16px',
                          height: '16px',
                        }}
                      >
                        <GuestTagIcon />
                      </ReservationIconWrapper>
                    )}
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', lineHeight: 1 }}>
                  <Box sx={{ display: 'flex', gap: '4px', marginBottom: '2px' }}>
                    <Box className="reservation-wrapper__stats-time">
                      {showTime && `${formattedTimeRange}`}
                    </Box>
                    <OriginalTime
                      reservation={reservation}
                      showInitialTimeBadge={showInitialTimeBadge}
                    />
                    {settings.spinner && (
                      <CircularProgress
                        size={12}
                        className={'reservation-spinner'}
                        color="inherit"
                      />
                    )}
                  </Box>
                  <div className="reservation-wrapper__stats">
                    <Box
                      component="ul"
                      sx={{ display: 'flex', alignItems: 'center', minHeight: '14px' }}
                    >
                      {isImportantMessage && (
                        <ReservationIconWrapper className="timeline-icon-wrapper important-message">
                          <ImportantMessageIcon />
                        </ReservationIconWrapper>
                      )}

                      {isLocked && (
                        <ReservationIconWrapper className="timeline-icon-wrapper important-message">
                          <LockIcon />
                        </ReservationIconWrapper>
                      )}

                      {guestCondition && (
                        <ReservationIconWrapper>{item?.reservation?.guests}</ReservationIconWrapper>
                      )}

                      {reservation.status === Statuses.WalkIn && (
                        <ReservationIconWrapper>
                          <WalkinsIcon />
                        </ReservationIconWrapper>
                      )}

                      {originCondition && (
                        <ReservationIconWrapper>
                          <OriginIcon origin={reservation.origin} />
                        </ReservationIconWrapper>
                      )}

                      {specialOfferCondition && (
                        <ReservationIconWrapper>
                          <SpecialOfferIcon />
                        </ReservationIconWrapper>
                      )}

                      {showCommentCondition && (
                        <ReservationIconWrapper>
                          <CommentIcon />
                        </ReservationIconWrapper>
                      )}

                      {isDietaryRequirementExist && (
                        <ReservationIconWrapper>
                          <DietaryRequirementIcon />
                        </ReservationIconWrapper>
                      )}
                      {isPromotionType && <PromotionIcons item={reservation} />}
                      {tagsToRender}
                    </Box>
                  </div>
                </Box>
              </div>
            )}
          </Box>

          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
        </div>
      );
    });

  return <div key={itemProps.key}>{child}</div>;
};
