import { ReactNode } from 'react';
import { SxProps, Typography } from '@mui/material';

interface OwnProps {
  children: ReactNode | string;
  sx?: SxProps;
}

export const StyledTypography = ({ children, sx }: OwnProps) => {
  return <Typography sx={{ fontSize: 16, fontWeight: 600, ...sx }}>{children}</Typography>;
};
